@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.ttf') format('truetype'),
  url('../fonts/Roboto-Bold.ttf') format('truetype'); /* Add more font weights if needed */
  /* Add other font formats (woff, woff2) and font weights as required */
}

body {
  font-family: 'Roboto', sans-serif; /* Use the self-hosted Roboto font */
}

/* For dark mode animation */
@keyframes fill-up {
  from {
    height: 0%;
  }
  to {
    height: 100%;
  }
}

body.dark::before {
  content: '';
  position: fixed; /* Cover the entire screen */
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #303338; /* Dark mode background color */
  animation: fill-up 0.5s ease forwards;
  z-index: -1;
}

body:not(.dark)::before {
  content: '';
  position: fixed; /* Cover the entire screen */
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f5f1fa; /* Light mode background color */
  animation: fill-up 0.5s ease forwards;
  z-index: -1;
}

/* Define a keyframes animation for background color fade-in */
@keyframes backgroundFadeIn {
  from {
    background-color: var(--start-color);
  }
  to {
    background-color: var(--end-color);
  }
}

/* Animation class for the container */
.background-fade-in-animation {
  animation: backgroundFadeIn 0.5s ease forwards;
}

/*Safari iOS zoom on input fix*/
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}